<template>
  <admin>
    <metatag title="Master List"></metatag>

    <!-- redirect to upload form -->
    <page-header>
      <template v-slot:action>
        <v-btn
          :to="{ name: 'dealerParts.masterlist.upload' }"
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="info"
          exact
        >
          <v-icon small left>mdi-cloud-upload</v-icon>
          <span v-text="'Upload'"></span>
        </v-btn>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu
        ref="toolbar"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
        @update:model="handleFilterModel"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="resources.headers"
          :items="resources.data"
          :loading="resources.loading"
          :items-per-page="5"
          :server-items-length="resources.total"
          :options.sync="resources.options"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >

        <template v-slot:item.parts_number="{ item }">
            <code>{{ item.parts_number }}</code>
        </template>

        <template v-slot:item.updated_at="{ item }">
            <span>{{ formatDate(item.updated_at) }}</span>
        </template>

        <template v-slot:item.description="{ item }">
            <small style="font-size: 80% !important;">{{ item.description }}</small>
        </template>

        <template v-slot:item.created_at="{ item }">
            <span>{{ formatDate(item.created_at) }}</span>
        </template>

        <template v-slot:item.function_&_function_type="{ item }">
            {{ item.functions }} <br />
            {{ item.function_type }}
        </template>

        <template v-slot:item.model_year="{ item }">
            {{ item.model  }}
            {{ item.year }}
        </template>


        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      filters: [
        { text: "Model", value: "model" },
        { text: "Variant", value: "name" },
        { text: "Year", value: "year" },
        { text: "Price", value: "price" },
        { text: "Status", value: "status" },
      ],
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
      resources: {
        loading: true,
        headers: [
          {
            text: "ID",
            align: "left",
            value: "id"
          },
          {
            text: "Part Number",
            align: "left",
            value: "parts_number"
          },
          {
            text: "Description",
            align: "left",
            value: "description"
          },
          {
            text: "Function & Function Type",
            align: "left",
            value: "function_&_function_type"
          },
      
          {
            text: "Model & Year",
            align: "left",
            value: "model_year"
          },
          {
            text: "Updated At",
            align: "left",
            value: "updated_at"
          },
          {
            text: "Created At",
            align: "left",
            value: "created_at"
          },
        ],
        options: {},
        data: [],
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      }
    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "masterlist/GET_MASTERLIST",
    }),
  },
  methods: {
    ...mapActions({
      getMasterlist: "masterlist/getMasterlist"
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "", model = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (model) {
        data.model = model;
      }
      this.getMasterlist(data).then(() => {
        this.resources.data = this.data.data;
        this.resources.total = this.data.meta.total;
        this.resources.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    async handleFilterModel(val) {
      await this.getItems(1, 5, "", val);
    },

    formatDate(date){
      return helpers.format_date(date);
    }
  },
};
</script>

<style></style>
