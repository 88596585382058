var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Master List"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"to":{ name: 'dealerParts.masterlist.upload' },"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"info","exact":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cloud-upload")]),_c('span',{domProps:{"textContent":_vm._s('Upload')}})],1)]},proxy:true}])}),_c('v-card',[_c('toolbar-menu',{ref:"toolbar",attrs:{"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch,"update:model":_vm.handleFilterModel}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.resources.headers,"items":_vm.resources.data,"loading":_vm.resources.loading,"items-per-page":5,"server-items-length":_vm.resources.total,"options":_vm.resources.options,"color":"primary","item-key":"id"},on:{"update:options":function($event){return _vm.$set(_vm.resources, "options", $event)}},scopedSlots:_vm._u([{key:"item.parts_number",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.parts_number))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('small',{staticStyle:{"font-size":"80% !important"}},[_vm._v(_vm._s(item.description))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.function_&_function_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.functions)+" "),_c('br'),_vm._v(" "+_vm._s(item.function_type)+" ")]}},{key:"item.model_year",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.model)+" "+_vm._s(item.year)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }